html {
  scrollbar-gutter: stable;
}
html,
body {
  width: 100%;
  touch-action: manipulation;
}
body {
  margin: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
#root {
  width: 100%;
  min-height: 100vh;
}
input,
textarea {
  outline: none;
}
a {
  text-decoration: none;
}
